import { useQuery } from "@apollo/react-hooks";
import { message } from "antd";
import { sortBy } from "lodash";
import moment, { duration } from "moment";
import React, { createContext, useEffect, useState, useMemo } from "react";
import { uuidv4 } from "./helpers/helpers";
import { ALL_CONFIGURATIONS } from "./modules/main/ConfigurationsQueries";

const format = "hh:mm:ss";

const formatDuration = totalTime => {
  const hours =
    totalTime.get("hours") < 10
      ? `0${totalTime.get("hours")}`
      : `${totalTime.get("hours")}`;
  const minutes =
    totalTime.get("minutes") < 10
      ? `0${totalTime.get("minutes")}`
      : `${totalTime.get("minutes")}`;
  const seconds =
    totalTime.get("seconds") < 10
      ? `0${totalTime.get("seconds")}`
      : `${totalTime.get("seconds")}`;

  return `${hours}:${minutes}:${seconds}`;
};

const calcArrivalAndDeparture = optimizedRoutes => {
  const { routes } = optimizedRoutes;

  const mappedRoutes = routes.map(route => {
    let formerId = 1234;

    let estimatedArrival = route.services[0].service_start || "08:00:00";
    let estimatedDeparture = route.services[0].service_finish || "18:00:00";
    let travelTime = "00:00:00";

    const mappedServices = route.services.map((service, index) => {
      const matrix = service.identification.find(({ id }) => id === formerId);
      travelTime = matrix.time;

      if (index === 0) {
        estimatedArrival = moment(estimatedArrival, format)
          .add(duration(travelTime, format))
          .format(format);
        estimatedDeparture = moment(estimatedArrival, format)
          .add(duration(service.operational_duration, format))
          .format(format);
      } else {
        estimatedArrival = moment(estimatedDeparture, format)
          .add(duration(travelTime, format))
          .format(format);
        estimatedDeparture = moment(estimatedArrival, format)
          .add(duration(service.operational_duration, format))
          .format(format);
      }

      formerId = service.id;

      return { ...service, estimatedArrival, estimatedDeparture };
    });

    return { ...route, services: mappedServices };
  });

  optimizedRoutes.routes = mappedRoutes;

  return { ...optimizedRoutes };
};

const recalcRouteAttributes = optimizedRoutes => {
  optimizedRoutes = calcArrivalAndDeparture(optimizedRoutes);

  optimizedRoutes.routes = optimizedRoutes.routes.map(route => {
    if (route.services.length === 0) return { ...route, time: 0, distance: 0 };

    const {
      distance: initialDistance,
      time: intialTime
    } = route.services[0].identification.find(({ id }) => id === 1234);
    const { distance: finalDistance, time: finalTime } = route.services[
      route.services.length - 1
    ].identification.find(({ id }) => id === 4321);

    let totalTime = duration(intialTime, format).add(
      duration(finalTime, format)
    );
    let totalDistance = parseFloat(initialDistance) + parseFloat(finalDistance);

    route.services.forEach((service, index) => {
      if (index === route.services.length - 1) return;

      const nextId = route.services[++index].id;
      const { distance, time } = service.identification.find(
        ({ id }) => id === nextId
      );

      totalTime = totalTime
        .add(duration(time, format))
        .add(duration(service.operational_duration, format));
      totalDistance = parseFloat(totalDistance) + parseFloat(distance);
    });

    return {
      ...route,
      time: formatDuration(totalTime),
      distance: Number(totalDistance.toFixed(2))
    };
  });

  return { ...optimizedRoutes };
};

const sendRoute = async data => {
  const URL = process.env.REACT_APP_OPTIMIZER_SERVER;
  try {
    const response = await fetch(URL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data)
    });
    const json = await response.json();
    json.routes = json.routes.map(route => {
      return {
        vehicle: route.vehicles,
        ...route
      };
    });

    if (json.hasOwnProperty("no_routes")) {
      const { services } = json.no_routes;
      json.routes.push({ distance: 0, time: 0, services });
    }

    return recalcRouteAttributes(json);
  } catch (e) {
    console.error(e);
    message.error("Falha ao otimizar rotas");
  }
};

export const ServicesContext = createContext({
  origin: {},
  services: [],
  destination: {},
  serviceStart: "",
  serviceFinish: "",
  optimizedRoutes: {},
  selectedVehicles: [],
  onDragEnd: result => void 0,
  addService: service => void 0,
  selectOrigin: origin => void 0,
  deleteService: serviceId => void 0,
  setOptmizedRoutes: routes => void 0,
  selectDestination: destination => void 0,
  updateService: (index, service) => void 0,
  selectDriver: (driver, columnId) => void 0,
  generateOptimizedRoutes: async () => void 0,
  selectVehicle: (vehicle, columnId) => void 0,
  setSelectedVehicles: selectedVehicles => void 0
});

export const ServicesContextProvider = props => {
  const [origin, setOrigin] = useState({});
  const [services, setServices] = useState([]);
  const [destination, setDestination] = useState({});
  const [serviceStart, setServiceStart] = useState("08:00");
  const [serviceFinish, setServiceFinish] = useState("18:00");
  const [optimizedRoutes, setOptimizedRoutes] = useState({ routes: [] });
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [invalidServices, setInvalidServices] = useState([]);
  const [userLocation, setUserLocation] = useState({
    lat: -12.9803996,
    lng: -38.4570069
  });

  const bounds = useMemo(
    () =>
      new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(userLocation.lat, userLocation.lng)
      ),
    [userLocation]
  );

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(({ coords }) => {
      const { latitude, longitude } = coords;
      setUserLocation({ lat: latitude, lng: longitude });
    });
  }, []);

  const [configurations, setConfigurations] = useState({
    operational_limit: "10:00",
    service_start: "08:00",
    service_finish: "18:00",
    operational_duration: "00:20"
  });

  const { data = { configuration: [] } } = useQuery(ALL_CONFIGURATIONS);

  useEffect(() => {
    const { configuration } = data;

    if (configuration && configuration.length) {
      const orderedArray = sortBy(configuration, c => c.updated_at);
      const config = orderedArray[0];

      setConfigurations(config);
      setServiceStart(config.service_start);
      setServiceFinish(config.service_finish);
    }
  }, [data]);

  const addService = service =>
    setServices(services => [...services, { id: uuidv4(), ...service }]);

  const deleteService = serviceId =>
    setServices(services => services.filter(({ id }) => !(id === serviceId)));

  const updateService = (index, service) => {
    const servicesCopy = [...services];
    servicesCopy[index] = service;
    setServices(servicesCopy);
  };

  const addInvalidService = service =>
    setInvalidServices(services => [...services, { id: uuidv4(), ...service }]);

  const deleteInvalidService = serviceId =>
    setInvalidServices(services =>
      services.filter(({ id }) => !(id === serviceId))
    );

  const selectOrigin = place => {
    const location = {
      address: place.formatted_address,
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng()
    };
    setOrigin({ ...location, id: 1234 });
    setDestination({ ...location, id: 4321 });
  };

  const selectDestination = place => {
    const location = {
      address: place.formatted_address,
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng()
    };
    setDestination({ ...location, id: 4321 });
  };

  const updateOrigin = place => {
    setOrigin({ ...place, id: 1234 });
    setDestination({ ...place, id: 4321 });
  };

  const updateDestination = place => {
    setDestination({ ...place, id: 4321 });
  };

  const selectServiceStart = (_, timeString) => {
    setServiceStart(timeString);
  };

  const selectServiceFinish = (_, timeString) => {
    setServiceFinish(timeString);
  };

  const selectDriver = (driver, columnId) => {
    const copy = { ...optimizedRoutes };
    copy.routes[columnId].driver = driver;
    setOptimizedRoutes(copy);
  };

  const selectVehicle = (vehicle, columnId) => {
    const copy = { ...optimizedRoutes };
    copy.routes[columnId].vehicle = vehicle;
    setOptimizedRoutes(copy);
  };

  const generateOptimizedRoutes = async () => {
    if (!(origin.lat || origin.lng)) {
      message.warn("Por favor, insira origem");
      return;
    }

    if (!(destination.lat || destination.lng)) {
      message.warn("Por favor, insira destino");
      return;
    }

    if (!services.length) {
      message.warn("Por favor, insira pelo menos um serviço");
      return;
    }

    if (!selectedVehicles.length) {
      message.warn("Por favor, selecione pelo menos um veículo");
      return;
    }

    const data = {
      origin: {
        name: origin.address,
        ...origin,
        service_start: serviceStart
      },
      destination: {
        name: destination.address,
        ...destination,
        service_finish: serviceFinish
      },
      vehicles: [...selectedVehicles],
      services,
      operational_limit: configurations.operational_limit
    };

    try {
      const result = await sendRoute(data);
      if (!result) return;
      setOptimizedRoutes(recalcRouteAttributes(result));
    } catch (e) {
      console.error(e.message);
    }
  };

  const onDragEnd = result => {
    const { destination, source, draggableId } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    const startRoute = optimizedRoutes.routes[source.droppableId];
    const finishRoute = optimizedRoutes.routes[destination.droppableId];

    if (startRoute === finishRoute) {
      const newServiceIds = Array.from(startRoute.services.map(s => s.id));
      newServiceIds.splice(source.index, 1);
      newServiceIds.splice(destination.index, 0, draggableId);

      const newRoute = {
        ...startRoute,
        services: newServiceIds.map(id => {
          return startRoute.services.filter(s => s.id === id)[0];
        })
      };

      optimizedRoutes.routes[source.droppableId] = newRoute;
      const modifiedRoute = recalcRouteAttributes(optimizedRoutes);
      setOptimizedRoutes(modifiedRoute);
      return;
    }

    // Move from one list to another

    const startServiceIds = Array.from(startRoute.services.map(s => s.id));
    startServiceIds.splice(source.index, 1);
    const newStart = {
      ...startRoute,
      services: startServiceIds.map(id => {
        return startRoute.services.filter(s => s.id === id)[0];
      })
    };

    const finishServiceIds = Array.from(finishRoute.services.map(s => s.id));
    finishServiceIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finishRoute,
      services: finishServiceIds.map(id => {
        return (
          finishRoute.services.filter(s => s.id === id)[0] ||
          startRoute.services.filter(s => s.id === id)[0]
        );
      })
    };

    optimizedRoutes.routes[source.droppableId] = newStart;
    optimizedRoutes.routes[destination.droppableId] = newFinish;
    const modifiedRoute = recalcRouteAttributes(optimizedRoutes);
    setOptimizedRoutes(modifiedRoute);

    return;
  };

  return (
    <ServicesContext.Provider
      value={{
        bounds,
        origin,
        services,
        onDragEnd,
        addService,
        destination,
        selectOrigin,
        serviceStart,
        selectDriver,
        updateOrigin,
        selectVehicle,
        serviceFinish,
        deleteService,
        updateService,
        configurations,
        optimizedRoutes,
        invalidServices,
        selectedVehicles,
        selectDestination,
        updateDestination,
        addInvalidService,
        setOptimizedRoutes,
        selectServiceStart,
        deleteInvalidService,
        selectServiceFinish,
        setSelectedVehicles,
        generateOptimizedRoutes
      }}
    >
      {props.children}
    </ServicesContext.Provider>
  );
};
