import { Form, Input, SubmitButton } from "formik-antd";
import { Layout, message } from "antd";
import { Formik, FormikActions, FormikValues } from "formik";
import React from "react";
import * as Yup from "yup";
import { FirebaseError } from "firebase";
import fb from "./firebaseConfig";

const { Content } = Layout;

interface SubmitValues {
  email: string;
  password: string;
}

const SigninSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email inválido")
    .required("Campo obrigatório"),
  password: Yup.string().required("Campo obrigatório")
});

const handleSubmit = async (
  values: FormikValues,
  { setSubmitting }: FormikActions<SubmitValues>
) => {
  const { email, password } = values;
  try {
    await fb.auth().signInWithEmailAndPassword(email, password);
  } catch (error) {
    handleSignInError(error);
  }
  setSubmitting(false);
};

const handleSignInError = (error: FirebaseError) => {
  let msg = "";
  switch (error.code) {
    case "auth/user-not-found":
      msg = "Usuário não encontrado";
      break;
    case "auth/wrong-password":
      msg = "Senha incorreta";
      break;
    default:
      msg = "Falha ao autenticar usuário";
  }
  message.warning(msg);
};

const SignIn = () => {
  return (
    <Layout className="layout" style={{ height: "100vh" }}>
      <Content>
        <div
          style={{
            margin: "0 auto",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)"
          }}
        >
          <h2 style={{ textAlign: "center" }}>INTERAKT | Roteirizador</h2>

          <Formik
            initialValues={{
              email: "",
              password: ""
            }}
            validationSchema={SigninSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <Form.Item name="email" label="Email" style={{ marginBottom: 0 }}>
                <Input name="email" placeholder="Email" />
              </Form.Item>

              <Form.Item name="password" label="Password">
                <Input name="password" type="password" placeholder="Password" />
              </Form.Item>
              <Form.Item name="submit">
                <SubmitButton
                  type="primary"
                  style={{
                    backgroundColor: "#001132",
                    color: "#fff",
                    width: "300px"
                  }}
                >
                  Log in
                </SubmitButton>
              </Form.Item>
            </Form>
          </Formik>
        </div>
      </Content>
    </Layout>
  );
};

export default SignIn;
