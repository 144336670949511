import { GoogleMap, Marker } from "@react-google-maps/api";
import { Button, Col, Icon, Modal, Row } from "antd";
import { Formik } from "formik";
import { Form, TimePicker } from "formik-antd";
import moment from "moment";
import React, { useContext, useState } from "react";
import AddressAutocomplete from "react-google-autocomplete";
import styled from "styled-components";
import { ServicesContext } from "../../ServicesContext";

const format = "HH:mm";

const Container = styled.div`
  padding: 30px;
  margin-bottom: 30px;
  background-color: #ececec;
`;

export const OperationalForm = () => {
  const {
    bounds,
    origin,
    destination,
    selectOrigin,
    serviceStart,
    serviceFinish,
    selectDestination,
    selectServiceStart,
    selectServiceFinish
  } = useContext(ServicesContext);

  return (
    <Container>
      <Formik>
        <Form>
          <Row gutter={4} align="bottom" type="flex">
            <Col span={18}>
              <Form.Item name="origin" label="Origem">
                <AddressAutocomplete
                  key={bounds.toString()}
                  className="ant-input"
                  type="text"
                  name="origin"
                  bounds={bounds}
                  defaultValue={(origin && origin.address) || ""}
                  placeholder="Origem"
                  onPlaceSelected={selectOrigin}
                  types={["address"]}
                  componentRestrictions={{ country: "br" }}
                />
              </Form.Item>
            </Col>
            <Col span={1}>
              <EditAddress field="origin" location={origin} />
            </Col>
            <Col span={4}>
              <Form.Item name="serviceStart" label="Início da Operação">
                <TimePicker
                  allowClear={false}
                  style={{ width: "100%" }}
                  name="serviceStart"
                  format={format}
                  value={moment(serviceStart, format)}
                  onChange={selectServiceStart}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={4} align="bottom" type="flex">
            <Col span={18}>
              <Form.Item name="destination" label="Destino">
                <AddressAutocomplete
                  key={bounds.toString()}
                  className="ant-input"
                  type="text"
                  name="destination"
                  bounds={bounds}
                  defaultValue={(destination && destination.address) || ""}
                  placeholder="Destino"
                  onPlaceSelected={selectDestination}
                  types={["address"]}
                  componentRestrictions={{ country: "br" }}
                />
              </Form.Item>
            </Col>
            <Col span={1}>
              <EditAddress field="destination" location={destination} />
            </Col>
            <Col span={4}>
              <Form.Item name="service_finish" label="Fim da Operação">
                <TimePicker
                  allowClear={false}
                  style={{ width: "100%" }}
                  name="service_finish"
                  format={format}
                  value={moment(serviceFinish, format)}
                  onChange={selectServiceFinish}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Formik>
    </Container>
  );
};

const EditAddress = ({ field, location }) => {
  const [modal, setModal] = useState(false);
  const { updateOrigin, updateDestination } = useContext(ServicesContext);

  const openModal = () => setModal(true);

  const singleClick = ({ latLng }) => {
    const geocoder = new window.google.maps.Geocoder();
    const lat = latLng.lat();
    const lng = latLng.lng();
    let address = "";

    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          address = results[0]["formatted_address"];
          if (field === "origin") {
            updateOrigin({ lat, lng, address });
          } else if (field === "destination") {
            updateDestination({ lat, lng, address });
          }
        } else {
          window.alert("No results found");
        }
      } else {
        window.alert("Geocoder failed due to: " + status);
      }
    });
  };

  const doubleClick = () => {
    return;
  };

  let clickCount = 0;
  let singleClickTimer = "";

  const handleClicks = (event, setFieldValue, oldValues) => {
    clickCount++;
    if (clickCount === 1) {
      singleClickTimer = setTimeout(() => {
        clickCount = 0;
        singleClick(event, setFieldValue, oldValues);
      }, 300);
    } else if (clickCount === 2) {
      clearTimeout(singleClickTimer);
      clickCount = 0;
      doubleClick();
    }
  };

  return (
    <>
      <Button style={{ marginBottom: 28 }} block onClick={openModal}>
        <Icon type="environment" theme="outlined" />
      </Button>
      <Modal
        visible={modal}
        footer={null}
        width={1200}
        bodyStyle={{ padding: 0 }}
        onCancel={() => setModal(false)}
        destroyOnClose={true}
      >
        <div
          style={{
            height: 700,
            boxShadow: "-8px 8px 20px 8px rgba(0,0,0,0.26)"
          }}
        >
          <GoogleMap
            id="direction-example"
            mapContainerStyle={{
              height: "100%",
              width: "100%"
            }}
            options={{
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false
            }}
            clickableIcons={false}
            zoom={location && location.lat && location.lng ? 16 : 8}
            center={
              location && location.lat && location.lng
                ? location
                : { lat: -12.9662976, lng: -38.453248 }
            }
            onDblClick={handleClicks}
            onClick={handleClicks}
          >
            {location && location.lat && location.lng && (
              <Marker position={location} title={location.address} />
            )}
          </GoogleMap>
        </div>
      </Modal>
    </>
  );
};
