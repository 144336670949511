import gql from "graphql-tag";

// id
export const CONFIGURATION_FRAGMENT = gql`
  fragment Configuration on configuration {
    service_start
    service_finish
    operational_limit
    operational_duration
    updated_at
  }
`;

export const ALL_CONFIGURATIONS = gql`
  ${CONFIGURATION_FRAGMENT}
  query AllConfigurations {
    configuration {
      ...Configuration
    }
  }
`;
