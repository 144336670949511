import { Button, Row, Spin } from "antd";
import gql from "graphql-tag";
import React, { useContext, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import styled from "styled-components";
import Column from "./Column";
import { ServicesContext } from "../../ServicesContext";
import { useQuery } from "@apollo/react-hooks";
import { ALL_VEHICLES_QUERY } from "../vehicles/VehiclesQueries";
import { ALL_DRIVERS_QUERY } from "../drivers/DriversQueries";

const Container = styled.div`
  display: flex;
  overflow: auto;
`;

export const DRIVER_FRAGMENT = gql`
  fragment Driver on drivers {
    id
    cnh
    cpf
    name
    birthday
    cnh_category
  }
`;

export const DRIVERS_QUERY = gql`
  ${DRIVER_FRAGMENT}
  query AllDrivers {
    drivers {
      ...Driver
    }
  }
`;

const StepThree = () => {
  const [loading, setLoading] = useState(false);

  const {
    optimizedRoutes = null,
    generateOptimizedRoutes,
    onDragEnd,
  } = useContext(ServicesContext);

  const { data: vehicles_result } = useQuery(ALL_VEHICLES_QUERY);
  const { vehicles = [] } = vehicles_result;

  const { data: drivers_result } = useQuery(ALL_DRIVERS_QUERY);
  const { driver: drivers } = drivers_result;

  console.log(drivers, drivers_result);

  const { routes = [] } = optimizedRoutes;

  const handleOptimization = async () => {
    setLoading(true);
    await generateOptimizedRoutes();
    setLoading(false);
  };

  const selectedVehicles = routes
    .map((service) => (service.vehicle ? service.vehicle.id : null))
    .filter(Boolean);
  const selectedDrivers = routes
    .map((service) => (service.driver ? service.driver.id : null))
    .filter(Boolean);

  if (loading)
    return (
      <div
        style={{
          background: "#ECECEC",
          padding: "30px",
          height: "500px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Row gutter={8} type="flex" justify="space-around" align="middle">
          <Spin size="large" />
          <p>Otimizando Rotas...</p>
        </Row>
      </div>
    );

  return routes.length ? (
    <DragDropContext onDragEnd={onDragEnd}>
      <Container>
        {routes.map((route, key) => {
          const filteredDrivers = drivers.filter(
            ({ id }) => !selectedDrivers.includes(id)
          );
          const filteredVehicles = vehicles.filter(
            ({ id }) => !selectedVehicles.includes(id)
          );
          return (
            <Column
              key={key}
              columnId={`${key}`}
              route={route}
              vehicles={filteredVehicles}
              drivers={filteredDrivers}
            />
          );
        })}
      </Container>
    </DragDropContext>
  ) : (
    <div
      style={{
        background: "#ECECEC",
        padding: "30px",
        height: "500px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Row gutter={8} type="flex" justify="space-around" align="middle">
        <Button onClick={handleOptimization}>Otimizar Rotas</Button>
      </Row>
    </div>
  );
};

export default StepThree;
