import { ApolloProvider } from "@apollo/react-hooks";
import { Button, Icon, Layout, Menu } from "antd";
import "antd/dist/antd.css";
import React, { Component } from "react";
import { Link, Router } from "react-router-dom";
import client from "./apolloClient";
import fb from "./firebaseConfig";
import history from "./history";
import Loading from "./Loading";
import Routes from "./modules/Routes";
import SignIn from "./Signin";

const { Header, Content, Sider } = Layout;

class App extends Component {
  state = {
    user: null,
    loading: true
  };

  componentDidMount = () => {
    fb.auth().onAuthStateChanged(async user => {
      if (user) {
        const token = await user.getIdToken();
        const idTokenResult = await user.getIdTokenResult();
        const hasuraClaim =
          idTokenResult.claims["https://hasura.io/jwt/claims"];

        if (hasuraClaim) {
          this.setState({ user, token });
        } else {
          // Check if refresh is required.
          const metadataRef = fb
            .database()
            .ref("metadata/" + user.uid + "/refreshTime");

          metadataRef.on("value", async () => {
            // Force refresh to pick up the latest custom claims changes.
            const token = await user.getIdToken(true);
            this.setState({ user, token });
          });
        }
      } else {
        this.setState({ user: null });
      }

      this.setState({ loading: false });
    });
  };

  handleSignout = () => {
    fb.auth().signOut();
  };

  render() {
    const { user, loading } = this.state;

    if (loading)
      return (
        <div
          style={{
            display: "flex",
            alignSelf: "center",
            justifyContent: "center",
            marginTop: "42vh"
          }}
        >
          <Loading type={"spin"} color={"#001132"} />
        </div>
      );
    return user ? (
      <ApolloProvider client={client}>
        <Router history={history}>
          <Layout>
            <Header
              style={{
                backgroundColor: "white",
                position: "fixed",
                zIndex: 1,
                width: "100%"
              }}
            >
              <Button
                onClick={this.handleSignout}
                type="link"
                style={{ float: "right", marginTop: 20 }}
              >
                <Icon type="logout" />
                <span style={{ marginLeft: 10 }} className="nav-text">
                  Sair
                </span>
              </Button>
              <p style={{ color: "#001132" }}>INTERAKT | Roteirizador</p>
            </Header>
            <br />
            <br />
            <br />
            <Layout>
              <Sider
                style={{
                  overflow: "auto",
                  height: "100vh",
                  position: "fixed",
                  left: 0
                }}
              >
                <Menu theme="dark" mode="inline">
                  <Menu.Item key="1">
                    <Link to="/">
                      <Icon type="compass" />
                      <span className="nav-text">Roteirizador</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="2">
                    <Link to="/clients/">
                      <Icon type="book" />
                      <span className="nav-text">Clientes</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="3">
                    <Link to="/vehicles/">
                      <Icon type="car" />
                      <span className="nav-text">Veículos</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="4">
                    <Link to="/drivers/">
                      <Icon type="team" />
                      <span className="nav-text">Motoristas</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="5">
                    <Link to="/config/">
                      <Icon type="control" />
                      <span className="nav-text">Configurações</span>
                    </Link>
                  </Menu.Item>
                </Menu>
              </Sider>
              <Layout style={{ marginLeft: 200 }}>
                <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
                  <div
                    style={{
                      padding: 24,
                      background: "#fff"
                    }}
                  >
                    <Routes />
                  </div>
                </Content>
              </Layout>
            </Layout>
          </Layout>
        </Router>
      </ApolloProvider>
    ) : (
      <SignIn />
    );
  }
}

export default App;
