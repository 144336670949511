import { useQuery } from "@apollo/react-hooks";
import { Button, Col, Icon, message, Row, Spin } from "antd";
import { Formik, FormikActions, FormikValues } from "formik";
import { Form, SubmitButton, TimePicker } from "formik-antd";
import gql from "graphql-tag";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import client from "../../apolloClient";
import history from "../../history";

const formatDuration = (totalTime: any) => {
  const hours =
    totalTime.get("hours") < 10
      ? `0${totalTime.get("hours")}`
      : `${totalTime.get("hours")}`;
  const minutes =
    totalTime.get("minutes") < 10
      ? `0${totalTime.get("minutes")}`
      : `${totalTime.get("minutes")}`;
  const seconds =
    totalTime.get("seconds") < 10
      ? `0${totalTime.get("seconds")}`
      : `${totalTime.get("seconds")}`;

  return `${hours}:${minutes}:${seconds}`;
};

interface IConfig {
  service_start: string;
  service_finish: string;
  operational_limit: string;
  operational_duration: string;
}

export const CONFIGURATION_FRAGMENT = gql`
  fragment Configuration on configuration {
    id
    service_start
    service_finish
    operational_limit
    operational_duration
  }
`;

const CONFIG_QUERY = gql`
  ${CONFIGURATION_FRAGMENT}
  query($id: uuid!) {
    configuration_by_pk(id: $id) {
      ...Configuration
    }
  }
`;

export const UPDATE_CONFIG_MUTATION = gql`
  ${CONFIGURATION_FRAGMENT}
  mutation($id: uuid!, $values: configuration_set_input!) {
    update_configuration(_set: $values, where: { id: { _eq: $id } }) {
      returning {
        ...Configuration
      }
    }
  }
`;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 }
};

const onSubmit = async (
  values: FormikValues,
  { setSubmitting }: FormikActions<IConfig>
) => {
  const { id, ...rest } = values;
  const { errors } = await client.mutate({
    mutation: UPDATE_CONFIG_MUTATION,
    variables: { id, values: { ...rest } }
  });

  !errors
    ? message.success("Configurações salvas com sucesso")
    : message.error("Falha ao salvar configurações");

  setSubmitting(false);
  history.push("/config/");
};

const ConfigForm = (props: any) => {
  const { id } = props.match.params;
  const { loading, data } = useQuery(CONFIG_QUERY, { variables: { id } });
  if (loading) return <Spin size="large" />;
  return (
    <>
      <div>
        <Row style={{ paddingBottom: "20px" }}>
          <Col span={8}>
            <Link to="/config">
              <Button>
                {" "}
                <Icon type="left" /> Voltar
              </Button>
            </Link>
          </Col>
          <Col span={8}>
            <h1 style={{ textAlign: "center" }}>Editar Configurações</h1>
          </Col>
          <Col span={8} />
        </Row>
      </div>
      <div>
        <Formik
          initialValues={data.configuration_by_pk}
          // validationSchema={driverSchema}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form {...formItemLayout}>
              <Form.Item
                name="operational_limit"
                label="Limite Operacional (h)"
              >
                <TimePicker
                  allowClear={false}
                  name="operational_limit"
                  defaultOpenValue={moment(
                    values.operational_limit,
                    "HH:mm:ss"
                  )}
                  value={moment(values.operational_limit, "HH:mm:ss")}
                  onChange={(momentVal: any) => {
                    const formattedDuration = formatDuration(momentVal);
                    setFieldValue("operational_limit", formattedDuration);
                  }}
                />
              </Form.Item>

              <Form.Item name="service_start" label="Início da Operação">
                <TimePicker
                  allowClear={false}
                  name="service_start"
                  defaultOpenValue={moment(values.service_start, "HH:mm:ss")}
                  value={moment(values.service_start, "HH:mm:ss")}
                  onChange={(momentVal: any) => {
                    const formattedDuration = formatDuration(momentVal);
                    setFieldValue("service_start", formattedDuration);
                  }}
                />
              </Form.Item>

              <Form.Item name="service_finish" label="Fim da Operação">
                <TimePicker
                  allowClear={false}
                  name="service_finish"
                  defaultOpenValue={moment(values.service_finish, "HH:mm:ss")}
                  value={moment(values.service_finish, "HH:mm:ss")}
                  onChange={(momentVal: any) => {
                    const formattedDuration = formatDuration(momentVal);
                    setFieldValue("service_finish", formattedDuration);
                  }}
                />
              </Form.Item>

              <Form.Item
                name="operational_duration"
                label="Tempo de Serviço Padrão"
              >
                <TimePicker
                  allowClear={false}
                  name="operational_duration"
                  defaultOpenValue={moment(
                    values.operational_duration,
                    "HH:mm:ss"
                  )}
                  value={moment(values.operational_duration, "HH:mm:ss")}
                  onChange={(momentVal: any) => {
                    const formattedDuration = formatDuration(momentVal);
                    setFieldValue("operational_duration", formattedDuration);
                  }}
                />
              </Form.Item>

              <Form.Item name="submit">
                <SubmitButton type="primary">Enviar</SubmitButton>
              </Form.Item>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ConfigForm;
